<template>
  <base-dialog v-model="dialog" :title="!loading ? 'Criar instância' : ''" :persistent="loading" :max-width="maxWidth">
    <v-container fluid>
      <v-row>
        <v-col v-if="!loading">
          <v-form ref="form" class="px-10 py-16" lazy-validation style="background-color: none;">
            <v-row>
              <v-col>
                <mf-text-input :value="dbName" label="Nome do banco de dados" readonly></mf-text-input>
              </v-col>
              <v-col>
                <mf-select v-model="branch" label="Branch" :rules="[notEmptyRule]" :items="['Alpha', 'Beta', 'Master']"></mf-select>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col cols="auto">
                  <mf-button color="error" icon="restart_alt" label="Cancelar" @click="closeDialog"></mf-button>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row justify="end">
                <v-col cols="auto">
                  <mf-button label="Salvar" icon="send" @click="clickButton"></mf-button>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else>
          <mf-loading-content :loading="true"></mf-loading-content>
        </v-col>
      </v-row>
    </v-container>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../../../components/atomic-components/atoms/BaseDialog.vue'
import { MUTATION_CREATE_INSTANCE } from '../../graphql/whatsapp'
export default {
  components: {
    BaseDialog
  },
  props: {
    /**
     * @model
     */
    value: {
      type: Boolean,
      default: false
    },
    /**
     * Database name
     */
    dbName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: this.value,
      maxWidth: '1000',
      branch: '',
      list: [],
      search: '',
      notEmptyRule: v => !!v || 'Campo obrigatório',
      loading: false
    }
  },
  computed: {
    context() {
      return {
        uri: this.$microservicesUrls['accounts'],
        headers: {
          authorization: localStorage.getItem('session_id')
        }
      }
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      if (!this.dialog) this.branch = ''
      this.$emit('input', this.dialog)
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', this.dialog)
      this.$emit('input', false)
    },
    async clickButton() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      const confirmation = await this.$confirm({
        title: 'Criar instância',
        message: 'Deseja criar a instância?',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      this.loading = true
      try {
        let variables = {
          input: {
            branch: this.branch.toLowerCase(),
            db_name: this.dbName
          }
        }
        await this.$apollo
          .mutate({
            mutation: MUTATION_CREATE_INSTANCE,
            variables,
            context: this.context
          })
          .then(res => {
            this.$alert({
              alert_message: 'Em breve, você será redirecionado para acompanhar o processo de criação da instância. Aguarde alguns segundos.',
              alert_title: 'Sucesso!',
              alert_color: 'success',
              alert_icon: 'mdi-check-circle'
            })
            setTimeout(() => {
              window.open(res.data.addWhatsAppInstance.url.toString(), '_blank')
              this.$emit('input', false)
              this.loading = false
            }, 10000)
          })
      } catch (error) {
        this.loading = false
        return this.$alert({
          alert_message: 'Falha ao criar instância!',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    }
  }
}
</script>

<style></style>

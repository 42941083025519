var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mf-loading-dialog',{attrs:{"loading":_vm.$apollo.loading}},[_c('base-page-layout',{attrs:{"title":"Instâncias Whatsapp"}},[_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mf-button',{staticClass:"mr-2",attrs:{"color":"primary","label":"Criar instância","icon":"add"},on:{"click":function($event){_vm.dialog = true}}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('mf-card-container',{attrs:{"title":"Instâncias","description":'Listagem das instâncias que estão vinculadas a conta ' + this.name}},[_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.listInstances,"footer-props":{ itemsPerPageOptions: [300] },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.number ? _vm.formatNumber(item.number) : '-')+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',[_c('v-icon',{attrs:{"x-small":"","color":item.active ? '#20c997' : '#ff6b6b'}},[_vm._v("mdi-brightness-1")]),_vm._v(" "+_vm._s(item.active ? 'Ativo' : 'Inativo')+" ")],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.excludeInstance(item._id)}}},[_c('v-list-item-title',[_vm._v("Excluir")])],1)],1)],1)]}}],null,true)})],1)],1)],1),_c('base-dialog',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('mf-loading-content',{attrs:{"loading":true}})],1),_c('create-instance',{attrs:{"db-name":_vm.dbName},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <mf-loading-dialog :loading="$apollo.loading">
    <base-page-layout title="Instâncias Whatsapp">
      <v-row>
        <v-col>
          <v-row no-gutters justify="end">
            <v-col cols="auto">
              <mf-button color="primary" class="mr-2" label="Criar instância" icon="add" @click="dialog = true"></mf-button>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <mf-card-container title="Instâncias" :description="'Listagem das instâncias que estão vinculadas a conta ' + this.name">
            <v-data-table :search="search" :headers="headers" :items="listInstances" :footer-props="{ itemsPerPageOptions: [300] }" :options.sync="options">
              <template v-slot:[`item.number`]="{ item }">
                {{ item.number ? formatNumber(item.number) : '-' }}
              </template>
              <template v-slot:[`item.active`]="{ item }">
                <v-flex>
                  <v-layout>
                    <v-icon x-small :color="item.active ? '#20c997' : '#ff6b6b'">mdi-brightness-1</v-icon>
                    {{ item.active ? 'Ativo' : 'Inativo' }}
                  </v-layout>
                </v-flex>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu :close-on-content-click="false">
                  <template #activator="{ on }">
                    <v-icon v-on="on">more_vert</v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="excludeInstance(item._id)">
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </mf-card-container>
        </v-col>
      </v-row>
      <base-dialog v-model="loading">
        <mf-loading-content :loading="true"></mf-loading-content>
      </base-dialog>
      <create-instance v-model="dialog" :db-name="dbName" />
    </base-page-layout>
  </mf-loading-dialog>
</template>

<script>
import CreateInstance from './CreateInstance.vue'
import BasePageLayout from '@/components/base/BasePageLayout'
import { GET_INSTANCES_WHATSAPP, MUTATION_DELETE_INSTANCE } from '../../graphql/whatsapp'
import { QUERY_GET_ACCOUNT } from '@/modules/accounts/graphql'
import BaseDialog from '../../../../components/atomic-components/atoms/BaseDialog.vue'

export default {
  components: {
    CreateInstance,
    BasePageLayout,
    BaseDialog
  },
  data() {
    return {
      search: '',
      accountId: '',
      listInstances: [],
      loading: false,
      headers: [
        {
          text: 'Id',
          value: '_id'
        },
        {
          text: 'Telefone',
          value: 'number'
        },
        {
          text: 'Status',
          value: 'active'
        },
        {
          text: 'Tag de deploy',
          value: 'deploy_tag'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      options: {},
      name: '',
      dialog: false,
      dbName: ''
    }
  },
  computed: {
    context() {
      return {
        uri: this.$microservicesUrls['accounts'],
        headers: {
          authorization: localStorage.getItem('session_id'),
          accountId: this.$route.params.id
        }
      }
    }
  },
  apollo: {
    instances: {
      query: GET_INSTANCES_WHATSAPP,
      fetchPolicy: 'no-cache',
      variables() {
        return Object.assign(
          {},
          {
            input: {
              account_id: this.$route.params.id
            }
          },
          {
            pagination: {
              page: this.options.page || 1,
              pageSize: this.options.itemsPerPage || 300,
              search: this.search || null,
              sort: this.options.sortBy || ['_id'],
              sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
            }
          }
        )
      },
      update({ getWhatsAppInstances }) {
        this.listInstances = getWhatsAppInstances
      },
      context() {
        return this.context
      }
    },
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.$route.params.id }
      },
      result({ data }) {
        this.dbName = data.adminAccount.db_name
        this.name = data.adminAccount.name
      }
    }
  },
  methods: {
    formatNumber(n) {
      const countryCode = n.slice(0, 2)
      const ddd = n.slice(2, 4)
      const phone = n.slice(4, n.length)
      return `+${countryCode} (${ddd}) ${phone}`
    },
    async excludeInstance(id) {
      const confirmation = await this.$confirm({
        title: 'Excluir Instância',
        message: 'Deseja Excluir a instância?',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      this.loading = true
      try {
        let variables = {
          instance_id: id
        }
        await this.$apollo
          .mutate({
            mutation: MUTATION_DELETE_INSTANCE,
            variables,
            context: this.context
          })
          .then(res => {
            this.$alert({
              alert_message: 'Em breve, você será redirecionado para acompanhar o processo de remoção da instância. Aguarde alguns segundos.',
              alert_title: 'Sucesso!',
              alert_color: 'success',
              alert_icon: 'mdi-check-circle'
            })
            setTimeout(() => {
              this.$apollo.queries.instances.refetch()
              window.open(res.data.removeWhatsAppInstance.url.toString(), '_blank')
              this.loading = false
            }, 10000)
          })
      } catch (error) {
        return this.$alert({
          alert_message: 'Falha ao remover instância!',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    }
  }
}
</script>
